$themes: (
  light: (
    primaryColor: #1973e8, // COLOR.primary    (on mw-style-react palette)
    workSpaceBg: #f6f7f8, // COLOR.secondary  (on mw-style-react palette)
    gray50: #eaecef, // COLOR.accent     (on mw-style-react palette)
    mainBg: #ffffff, // COLOR.white      (on mw-style-react palette)
    textColor: #393f48, // COLOR.black      (on mw-style-react palette)
    gray2: #7e8a9a, // COLOR.gray      (on mw-style-react palette)

    primaryColorHover: #1e6ad0,
    primary1Color: #649de7,
    secondaryColor: #e6f4fc,
    primary2Color: #fcfdff,
    primary10Color: #f3f9fe,
    primary30Color: #e7f3fe,
    primary40Color: #dbe9ff,
    primary50Color: #f9fbfc,
    primary60Color: #f0f6fd,
    primary70Color: #cddff5,
    primaryBgHover: #e8f1fd,
    gray1: #6e7f96,
    gray20: #f5f6f7,
    gray30: #ebedef,
    gray35: #acb3be,
    gray40: #eff1f2,
    gray60: #f4f4f4,
    gray70: #f0f0f0,
    green1: #2fb773,
    green5: #e8faea,
    green10: #d6f4e4,
    disabled: #dde0e5,
    red1: #e63c3c,
    red2: #e42f1d,
    red10: #fad8d8,
    yellow1: #fdab00,
    yellow2: #fc8d6a,
    yellow10: #fbe8b3,
    yellow20: #fec603,
    yellowBage: #fef6e5,
    orange1: #fdcbb1,
    orange2: #ffd8c4,
    violet1: #b8c0df,
    removeColorHover: #a90e00,
    errorChipColor: rgba(230, 60, 60, 0.1),
    treeBg: #ffffff,
    linkColor: #368abb,
    linkColorHover: #26688f,
    mainMenuColor: #6e7f96,
    mainMenuFooter: #f2f3f4,
    docTitleColor: #242d37,
    editedBadgeTextColor: #ffffff,
    editedBadgeBg: #dee3e8,
    newStatusColor: #e7f3fe,
    inProgressStatusColor: #ffeecc,
    reviewStatusColor: #d6f4e4,
    closeStatusColor: #f2f4f6,
    reopenStatusColor: #ffeecc,
    deletedStatusColor: #ffd6d7,
    newStatusCard: #f1f8ff,
    inProgressStatusCard: #fdf4de,
    reviewStatusCard: #dffaf0,
    closeStatusCard: #f4f5f8,
    reopenStatusCard: #fdf4de,
    deletedStatusCard: #fde5e7,
    headerIconColor: #c2c5c9,
    deepBlack: #000000,
    cardIconBg: #f8f9fa,
    cardBg: #ffffff,
    activeInputBgColor: #3c91e6,
    descrBg: #f8f9fa,
    descrBorder: #dee3e8,
    workSpaceBorder: #dde0e5,
    violets1: #9119e8,
    cardHeader: #393f48,
    reactionDoneColor: #34c21b,
    reactionDoneBackground: #f3fff1,
    reactionRejectColor: #e42f1d,
    reactionRejectBackground: #fff5f5,
    redBg: #ffe5e5,
    yellowBg: #fdf1d1,
    blueBg: #e7f1ff,
    redBorder: #f84545,
    yellowBorder: #fdac1b,
    purpleBg: #e4d3fc,
    greenBg: #e4f9e6,
    blue1: #d3e0e5,
    blue2: #aebcc4,
    blue3: #dfebf0,
    success: #22c933,
    fpsColor: #36BFFA,
    paleBlue: #eff6ff,
  ),
  dark: (
    primaryColor: #3c91e6,
    primaryColorHover: #1e6ad0,
    primary1Color: #649de7,
    secondaryColor: #e6f4fc,
    primary2Color: #fcfdff,
    primary10Color: #f3f9fe,
    primary30Color: #e7f3fe,
    primary40Color: #dbe9ff,
    primary50Color: #f9fbfc,
    primary60Color: #f0f6fd,
    primaryBgHover: #e8f1fd,
    gray1: #6e7f96,
    gray2: #7e8a9a,
    gray20: #f5f6f7,
    gray30: #ebedef,
    gray40: #eff1f2,
    green1: #2fb773,
    disabled: #dde0e5,
    red1: #e63c3c,
    red10: #fad8d8,
    yellow1: #fdab00,
    yellow2: #fca702,
    yellow10: #ffeecc,
    yellow20: #fec603,
    orange1: #fdcbb1,
    orange2: #ffd8c4,
    removeColorHover: #a90e00,
    errorChipColor: rgba(230, 60, 60, 0.1),
    textColor: #393f48,
    mainBg: #ffffff,
    treeBg: #ffffff,
    linkColor: #368abb,
    linkColorHover: #26688f,
    mainMenuColor: #6e7f96,
    mainMenuFooter: #f2f3f4,
    docTitleColor: #242d37,
    editedBadgeTextColor: #ffffff,
    editedBadgeBg: #dee3e8,
    newStatusColor: #e7f3fe,
    inProgressStatusColor: #ffeecc,
    reviewStatusColor: #d6f4e4,
    closeStatusColor: #f2f4f6,
    reopenStatusColor: #ffeecc,
    deletedStatusColor: #ffd6d7,
    newStatusCard: #f1f8ff,
    inProgressStatusCard: #fdf4de,
    reviewStatusCard: #dffaf0,
    closeStatusCard: #f4f5f8,
    reopenStatusCard: #fdf4de,
    deletedStatusCard: #fde5e7,
    headerIconColor: #c2c5c9,
    deepBlack: #000000,
    cardIconBg: #f8f9fa,
    cardBg: #ffffff,
    activeInputBgColor: #3c91e6,
    descrBg: #f8f9fa,
    descrBorder: #dee3e8,
    workSpaceBg: #f9fbfe,
    workSpaceBorder: #dde0e5,
    violets1: #9119e8,
    cardHeader: #393f48,
    reactionDoneColor: #34c21b,
    reactionDoneBackground: #f3fff1,
    reactionRejectColor: #e42f1d,
    reactionRejectBackground: #fff5f5,
    redBg: #ffe5e5,
    yellowBg: #fdf1d1,
    blueBg: #e7f1ff,
    redBorder: #f84545,
    yellowBorder: #fdac1b,
    purpleBg: #e4d3fc,
    success: #22c933,
    paleBlue: #eff6ff,
  ),
);

/*
 * Implementation of themes
 */
@mixin themify($themes) {
  @each $theme, $map in $themes {
    :global .theme-#{$theme} :local & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
