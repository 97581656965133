
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
@import '~@control-front-end/common/styles/variables';

.container {
  position: absolute;
  left: 0;
  top: 0;
  transform-origin: left top;
}
.activeCell,
.activeCellbg,
.activeCellBorder {
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.activeCellBorder {
  @include themify($themes) {
    border: 1px solid themed('primaryColor');
  }
}

.activeCellbg {
  @include themify($themes) {
    background-color: themed('paleBlue');
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blink 1.2s ease infinite;
}

.cellCoordLabel {
  position: absolute;
  bottom: 2px;
  right: 2px;
  line-height: 12px;
}
