
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.editor {
  z-index: 2;

  &__field {
    position: relative;
    z-index: auto;
    max-height: initial !important; // Disable max-height that was hardcoded in ContentEditable UI component
    // search field for smart chip
    :global .searchChip {
      display: inline-block;
      min-width: 86px;
      position: relative;

      &:empty:before {
        content: 'Search menu';
        position: absolute;
        top: 0;
        left: 0;
        display: contents;
        @include themify($themes) {
          color: themed('gray35') !important;
        }
      }
    }

    img {
      display: inline-block;
      cursor: pointer;
      max-width: 100%;
      min-height: 22px;
      max-height: 220px;
    }
  }
}
