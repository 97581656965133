
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.form {
  &__label {
    margin-bottom: 8px;
    display: block;

    &.focus {
      @include themify($themes) {
        color: themed('activeInputBgColor');
      }
    }

    &.error {
      @include themify($themes) {
        color: themed('red1');
      }
    }
  }

  &__btns {
    margin-top: 24px;
    text-align: right;

    & div {
      margin-left: 8px;
    }
  }

  &.editMode {
    // forbidden to remove sign/done/reject/freeze chips when editing a reaction
    :global div[class*='-artifacts-'] {
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    }
  }

  // search field for smart chip
  :global .searchChip {
    display: inline-block;
    min-width: 86px;
    position: relative;

    &:empty:before {
      content: 'Search menu';
      position: absolute;
      top: 0;
      left: 0;
      display: contents;
      @include themify($themes) {
        color: themed('gray35') !important;
      }
    }
  }

  :global .fileItemChip {
    margin-right: 0;
  }
}

@include respond-to(mobile) {
  .form {
    margin-bottom: 0;
  }
}

.snippetsPopupHolder {
  position: relative;
  margin-bottom: 4px;
}

.hiddenMessage {
  border-width: 2px !important;
  border-style: dashed !important;
  &:not([class*="focus"]) {
    @include themify($themes) {
      border-color: themed('gray2') !important;
    }
  }

  &__btn {
    cursor: pointer;
  }
}
