
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.effectsMenu {
  flex-wrap: wrap;
  max-height: 132px;
  padding: 8px 0;
  box-sizing: border-box;
  overflow: auto;
}

.effectBox {
  width: 64px;
  height: 48px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;

  &.active {
    @include themify($themes) {
      border-color: themed('primaryColor');
    }
  }

  &:hover:not(.active) {
    @include themify($themes) {
      border-color: themed('primary70Color');
    }
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.removeBtn {
  position: absolute !important;
  right: -6px;
  top: -6px;
  padding: 0 !important;

  i {
    border-radius: inherit;
    @include themify($themes) {
      background-color: themed('mainBg') !important;
    }
  }
}

.upload {
  overflow: hidden !important;
  div {
    width: inherit !important;
    height: inherit !important;
  }

  img {
    cursor: pointer;
    opacity: 1 !important;
  }

  input {
    width: 100%;
  }
}
