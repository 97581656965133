
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.videoPreview {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;
  aspect-ratio: 16 / 10;
  overflow: hidden;
  background-color: #1a1a1a;
  border-radius: 8px;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scaleX(-1);
  }
}

.videoPlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1a1a1a;
}

.videoEffectsButton {
  position: absolute !important;
  bottom: 12px;
  right: 12px;
}
