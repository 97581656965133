
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.savingLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  pointer-events: none;
  @include pagination-loader();
}
