//Clearfix
@mixin clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

//Clearfix end

@mixin open-sans {
  font-family: 'Open Sans', sans-serif;
}

@mixin text-overflow($important: null) {
  overflow: hidden $important;
  text-overflow: ellipsis $important;
  white-space: nowrap $important;
}

@mixin line-clamp($lines: 2, $whiteSpace: normal) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: $whiteSpace;
}

@mixin btn-icon() {
  position: relative;
  cursor: pointer;
  z-index: 0;

  &:after {
    z-index: -1;
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    left: -12px;
    top: -12px;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    background: none;
    border-radius: 50%;
    box-sizing: border-box;
    transform: scale(0);
    transition-property: transform, opacity;
    width: 40px;
    height: 40px;
  }

  &:hover:after {
    @include themify($themes) {
      background-color: themed('primary30Color');
    }

    border: none;
    opacity: 1;
    transform: scale(1);
  }

  &:hover :global .fill {
    @include themify($themes) {
      fill: themed('primaryColor') !important;
    }
  }
}

@mixin btn-icon-hover() {
  transition: 0.3s;

  &:hover :global .fill {
    @include themify($themes) {
      fill: themed('primaryColor') !important;
    }
  }
}

@mixin word-break {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

$laptop-width: 1366px;
$tablet-width: 768px;
$desktop-width: 1200px;

@mixin respond-to($device) {
  @if $device ==mobile {
    @media screen and (max-width: #{$tablet-width - 1px}) {
      @content;
    }
  } @else if $device ==tablet {
    @media screen and (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
      @content;
    }
  } @else if $device ==desktop {
    @media screen and (min-width: #{$desktop-width}) {
      @content;
    }
  } @else if $device ==laptop {
    @media screen and (min-width: #{$tablet-width}) and (max-width: #{$laptop-width - 1px}) {
      @content;
    }
  }
}

@mixin no-touch-select {
  -webkit-text-size-adjust: none;
  /* prevent webkit from resizing text to fit */
  -webkit-tap-highlight-color: transparent;
  /* prevent tap highlight color */
  -webkit-user-select: none;
  /* prevent copy paste, to allow, change 'none' to 'text' */
}

@mixin hide-scroll-bar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
}

@mixin show-scroll-bar {
  -ms-overflow-style: auto !important;
  /* Internet Explorer 10+ */
  scrollbar-width: auto !important;

  /* Firefox */
  &::-webkit-scrollbar {
    display: initial;
    /* Safari and Chrome */
  }
}

@mixin hover-scroll-bar {
  @include hide-scroll-bar();

  &:hover {
    @include show-scroll-bar();
  }
}

@mixin required-asterisk {
  &:after {
    content: ' *';
    display: inline;

    @include themify($themes) {
      color: themed('primaryColor');
    }
  }
}

@mixin popup-shadow {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

//Mixins end

@mixin flex-grid($cols, $gutter: 0) {
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-left: 0;
    margin-bottom: 0;
    $item-width: calc((100% / #{$cols}) - #{$gutter} + (#{$gutter} / #{$cols}));
    margin-right: $gutter;
    width: $item-width;
    box-sizing: border-box;

    &:nth-child(n + #{$cols + 1}) {
      margin-top: $gutter;
    }

    &:nth-child(#{$cols}n) {
      margin-right: 0;
    }
  }
}

// TODO remove have to fix style lib
@mixin modal-textfield {
  & :global .label {
    @include themify($themes) {
      color: themed('textColor') !important;
    }
  }

  & :global .field {
    @include themify($themes) {
      background-color: themed('mainBg') !important;
    }
  }

  &.error {
    & :global .field {
      @include themify($themes) {
        color: themed('red1') !important;
      }
    }
  }
}

// TODO remove have to fix style lib
@mixin modal-select {
  & :global .label {
    @include themify($themes) {
      color: themed('textColor') !important;
    }
  }

  & :global .field {
    @include themify($themes) {
      background-color: themed('mainBg') !important;
    }
  }

  &.error {
    & :global .field {
      @include themify($themes) {
        border-color: themed('red1') !important;
      }
    }
  }

  &.required {
    & :global .label {
      @include required-asterisk();
    }
  }
}

// TODO remove have to fix style lib
@mixin modal-label {
  @include themify($themes) {
    color: themed('textColor');
  }
}

@mixin header-icon {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  cursor: pointer;

  @include themify($themes) {
    background-color: themed('workSpaceBg');
  }

  &:hover {
    @include themify($themes) {
      background-color: themed('blueBg');
    }

    :global .fill {
      @include themify($themes) {
        fill: themed('primaryColor') !important;
      }
    }
  }
}

@mixin icon-primary {
  :global .fill {
    transition: 0.3s;
  }

  &:hover {
    :global .fill {
      @include themify($themes) {
        fill: themed('primaryColor') !important;
      }
    }
  }
}

@mixin pagination-loader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1;
  opacity: 0.5;
  pointer-events: none;

  @include themify($themes) {
    background-color: themed('mainBg');
  }
}

@mixin img_wrap_icon($size, $borderColor) {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  border-radius: 2px;

  @include themify($themes) {
    border: 1px solid themed($borderColor);
  }
}

@mixin table_cell_overflow_ellipsis($maxWidth) {
  display: inline-block;
  vertical-align: text-bottom;
  max-width: $maxWidth;
  @include text-overflow();
}

@mixin ghost {
  position: fixed !important;
  top: 0;
  opacity: 0;
  pointer-events: none;
}

// Mixin for centering the element absolutely within its container
@mixin center-absolute-on {
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

// Mixin for remove centering the element absolutely within its container
@mixin center-absolute-off {
  margin: unset;
  position: unset;
  left: unset;
  transform: unset;
}
